/** @jsx jsx */
import { jsx, Box, Button, Card, Container, Flex, Styled, Text } from "theme-ui"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import BackgroundImage from "gatsby-background-image"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Hero,
  LinkExt,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  HeroGradient,
  HighlightedProvider,
  LeadershipGrid,
  PatientStoryGrid,
  SectionHeading,
  Stats,
  Units,
} from "../../components/elements"
import CentersOfExcellenceLabels from "../../components/elements/CentersOfExcellenceLabels"

const About = ({ data, pageContext }) => {
  const page = data.datoCmsAbout
  const facilities = data.datoCmsFacility
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
            <HeroGradient>
              <Container
                sx={{
                  px: "1em",
                  py: [6, 6, 7],
                  zIndex: 10,
                  position: "relative",
                }}
              >
                <Styled.h1>{page.title}</Styled.h1>
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={page.title}
                />
              </Container>
              <ColorBar
                sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 20 }}
              />
            </HeroGradient>
          </BackgroundImage>
        </Hero>

        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box sx={{ width: ["full", "full", "4/5"] }}>
                <Styled.h6 as="p">{page.introContent}</Styled.h6>
              </Box>
              <Text
                variant="styles.twoCols"
                sx={{ width: ["full"] }}
                dangerouslySetInnerHTML={{
                  __html: page.contentNode.childMarkdownRemark.html,
                }}
              />
            </Flex>

            <Box width="full" pt={[24, 36]} sx={{ textAlign: "center" }}>
              <LinkExt to="https://www.flipsnack.com/wvumedicine/wvu-medicine-children-s-annual-report-2023/full-view.html">
                <Button as="span">View Our 2023 Report</Button>
              </LinkExt>
            </Box>
          </Container>
        </Section>

        {/* Highlighted Provider */}
        <Box as="section" id="highlighted" data-datocms-noindex>
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              <Box sx={{ width: "full", textAlign: "center", mb: 4 }}>
                <SectionHeading>Highlighted Providers</SectionHeading>
                <HighlightedProvider />
              </Box>
            </Flex>
          </Container>
        </Box>

        {/* Leadership Grid 
        <Section id="leadership">
          <Container sx={{ px: "1em" }}>
            <LeadershipGrid />
          </Container>
        </Section>

        */}

        {/* Facilities */}
        <Section id="facilities" sx={{ backgroundColor: ["warmgray"] }}>
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center", mb: 5 }}>
              <Card variant="wide">
                <Img
                  fluid={facilities.heroImage.fluid}
                  alt={"Image of facilities"}
                  sx={{
                    objectFit: "cover",
                    width: ["full", "full", "1/2"],
                    bg: "gray.3",
                  }}
                />

                <Box sx={{ width: ["full", "full", "1/2"], p: 4 }}>
                  <Styled.h2>{facilities.title}</Styled.h2>
                  <p>{facilities.introContent}</p>
                  <Link to={`/about-us/${facilities.slug}`}>
                    <Button as="span" variant="outline">
                      More About Our Facilities
                    </Button>
                  </Link>
                </Box>
              </Card>
            </Flex>

            {/* Centers of Excellence */}
            <CentersOfExcellenceLabels />

            {/* Units */}
            <Units />
          </Container>
        </Section>

        <Stats />

        {/* Stories */}
        <Section data-datocms-noindex>
          <Container>
            <Box sx={{ width: "full", textAlign: "center", mb: 4 }}>
              <SectionHeading>Patient Stories</SectionHeading>
              <PatientStoryGrid />
              <br />
            </Box>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default About

export const query = graphql`
  {
    datoCmsAbout {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        tags
      }
    }
    allDatoCmsLeadership(sort: { order: ASC, fields: fullName }) {
      edges {
        node {
          contentNode {
            childMarkdownRemark {
              html
            }
          }
          fullName
          id
          roleTitle
          slug
          portrait {
            fluid(
              maxWidth: 120
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "facearea"
                facepad: 5
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    datoCmsFacility {
      contentNode {
        childMarkdownRemark {
          excerpt
        }
      }
      id
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`
