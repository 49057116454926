/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import WufooForm from "react-wufoo-embed"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { ColorBar, HeroGradient } from "../../components/elements"

const Contact = ({ data, pageContext }) => {
  const page = data.datoCmsContact
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Main>
        {/* Hero */}
        <Hero>
          <BackgroundImage Tag="div" fluid={page?.heroImage?.fluid}>
            <HeroGradient>
              <Container sx={{ px: "1em", py: [6, 6, 7] }}>
                <Styled.h1>{page.title}</Styled.h1>
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={page?.title}
                />
              </Container>
            </HeroGradient>
            <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
          </BackgroundImage>
        </Hero>

        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box sx={{ width: ["full", "full", "4/5"] }}>
                <Styled.h6 as="p">{page?.introContent}</Styled.h6>
              </Box>
              <Box
                sx={{ mx: -2, px: [0, 0, 2], width: ["full", "full", "1/2"] }}
              >
                <WufooForm
                  userName="wvuhealthcare"
                  formHash="q1u85dht17xo91k"
                  header="hide"
                />
              </Box>
              <Text
                sx={{ px: [0, 0, 4], width: ["full", "full", "1/2"] }}
                dangerouslySetInnerHTML={{
                  __html: page?.contentNode?.childMarkdownRemark.html,
                }}
              />
              
            </Flex>
          </Container>
        </Section>

        {/* <Box id="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3071.8339544792907!2d-79.95966678435653!3d39.653451309627854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88357b0c1f3ffc2d%3A0x7c5bf278f52ebb!2s1%20Medical%20Center%20Dr%2C%20Morgantown%2C%20WV%2026506!5e0!3m2!1sen!2sus!4v1678307374785!5m2!1sen!2sus"
            width="100%"
            height="450"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
        </Box> */}
      </Main>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  {
    datoCmsContact(id: {eq: "DatoCmsContact-MNwAVu-LRnaWl4rojPIXzw-en"}) {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      id
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        tags
      }
    }
  }
`
